import React, { createContext, useContext, useState, useEffect } from "react";
import {
  RpcProvider,
  Contract,
  Account
} from "starknet";
import Decimal from "decimal.js";
import axios from "axios";
import { useToastContext } from "@/Context/ToastContext";
import { gql, useQuery } from "@apollo/client";
import {
  SPEED_COST_LEVEL_1_TO_2,
  SPEED_COST_LEVEL_2_TO_3,
  SPEED_COST_LEVEL_3_TO_4,
  SPEED_COST_LEVEL_4_TO_5,
  SPEED_COST_LEVEL_5_TO_6,
  SPEED_RATE_LEVEL_1,
  SPEED_RATE_LEVEL_2,
  SPEED_RATE_LEVEL_3,
  SPEED_RATE_LEVEL_4,
  SPEED_RATE_LEVEL_5,
  SPEED_RATE_LEVEL_6,
  STORAGE_COST_LEVEL_1_TO_2,
  STORAGE_COST_LEVEL_2_TO_2,
  STORAGE_COST_LEVEL_3_TO_2,
  STORAGE_COST_LEVEL_4_TO_2,
  STORAGE_COST_LEVEL_5_TO_2,
  STORAGE_LIMIT_LEVEL_1,
  STORAGE_LIMIT_LEVEL_2,
  STORAGE_LIMIT_LEVEL_3,
  STORAGE_LIMIT_LEVEL_4,
  STORAGE_LIMIT_LEVEL_5,
  STORAGE_LIMIT_LEVEL_6,
} from "./config_dislands";
import { useInitData, User } from "@telegram-apps/sdk-react";
import { roundTo6Decimals } from "@/pages/mainpage/convetTimstamp";

// GraphQL queries and mutations
export const GET_PLAYER_DATA = gql`
  query GetPlayerData($playerAddress: String!) {
    dislandsCoreMainnetStorageDataModels(where: { player: $playerAddress }) {
      edges {
        node {
          player
          last_claim
        }
      }
    }
    dislandsCoreMainnetPlayerModels(where: { player: $playerAddress }) {
      edges {
        node {
          player
          balance
          badge
        }
      }
    }
    dislandsCoreMainnetStatModels(where: { player: $playerAddress }) {
      edges {
        node {
          player
          upgrade_type
          level
        }
      }
    }
    dislandsCoreMainnetMusterModels(where: { player: $playerAddress }) {
      edges {
        node {
          player
          day_count
          last_muster
          level
        }
      }
    }
    dislandsCoreMainnetReferralsModels(where: { player: $playerAddress }) {
      edges {
        node {
          player
          index
          referred
          referal_reward
        }
      }
    }
  }
`;

// Context Interface
interface AccountContextProps {
  user: User | undefined;
  uri: string;
  userID: string | null;
  referredList: any;
  referredReward: any;
  totalReferredReward: number;
  shortAddress: string;
  publicKey: string | null;
  coinAmount: number;
  tokenAmount: number;
  fee: bigint;
  accountName: string;
  isEditing: boolean;
  deployAddress: string | null;
  accountAddress: string | null;
  privateKey: string | null;
  accountAX: Account;
  strk_token: string;
  playerData: any | null;
  nodeUrl: string;
  setNodeUrl: React.Dispatch<React.SetStateAction<string>>; 
  balanceModels: number;
  setBalance: any;
  last_claim: number;
  balance_new: number;
  boostLevel: number;
  storageLevel: number;
  speedLevel: number;
  dayCount: number;
  lastMuster: number;
  typeofChain: string;
  badge: number;
  setType: React.Dispatch<React.SetStateAction<string>>;
  contractAddress: string;
  priceFaucet: string;
  miningSpeed: {
    price: number;
    currentLevel: number;
    nextLevel: number;
    rate_current: number;
    rate_next: number;
  };
  miningStorage: {
    price: number;
    currentLevel: number;
    nextLevel: number;
    claimTime_current: number;
    claimTime_next: number;
  };
  handleCopyAddress: (value: string | null) => void;
  switchToMainnet: () => void;
  switchToTestnet: () => void;
  fetchCoinAmount: () => Promise<void>;
  handleNameChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleNameSubmit: () => void;
  setIsEditing: (value: boolean) => void;
  isNotification: boolean;
  setIsNotification: React.Dispatch<React.SetStateAction<boolean>>;
  triggerEffect: boolean;
  setTriggerEffect: React.Dispatch<React.SetStateAction<boolean>>;
  transactionHashes: TransactionHash[];
  addTransactionHash: (hash: string, type: string) => void;
}
interface TransactionHash {
  hash: string;
  type: string;
  timestamp: string;
}

// Creating Context
const AccountContext = createContext<AccountContextProps | undefined>(
  undefined
);

// Custom Hook to use Context
export const useAccountContext = (): AccountContextProps => {
  const context = useContext(AccountContext);
  if (!context) {
    throw new Error("useAccountContext must be used within an AccountProvider");
  }
  return context;
};

// Context Provider Component
export const AccountProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { showToast } = useToastContext();
  const [coinAmount, setCoinAmount] = useState<number>(0);
  const [tokenAmount, setTokenAmount] = useState<number>(0);
  const [accountName, setAccountName] = useState<string>(
    () => localStorage.getItem("account_name") || "Account 1"
  );
  const [isEditing, setIsEditing] = useState(false);
  const [playerData, setPlayerData] = useState<any | null>(null);
  const [balanceModels, setBalance] = useState<number>(0);
  const [last_claim, setLastClaim] = useState<number>(1);
  const [boostLevel, setBoost] = useState<number>(1);
  const [storageLevel, setStorage] = useState<number>(1);
  const [speedLevel, setSpeed] = useState<number>(1);
  const [badge, setBadge] = useState<number>(0);
  const [triggerEffect, setTriggerEffect] = useState(false);
  const [dayCount, setDayCount] = useState<number>(0);
  const [lastMuster, setLastMuster] = useState<number>(0);
  const [referredList, setReferredList] = useState([]);
  const [referredReward, setReferredReward] = useState([]);
  const [totalReferredReward, setTotalReferredReward] = useState(0);
  const balance_new = balanceModels;
  const initData = useInitData();
  const [user, setUser] = useState<User | undefined>(undefined);
  const nodeUrls = [
  "https://free-rpc.nethermind.io/mainnet-juno",
  "https://starknet-mainnet.infura.io/v3/9b7b6c404d364abfb43e7fe0d5074390",
  "https://starknet-mainnet.blastapi.io/43b86c66-8c36-42c1-ae72-57fe8082299e/rpc/v0_7",
  "https://g.w.lavanet.xyz/gateway/strk/rpc-http/3e389018f5cbc5496a59d9c394bd6e1c"
];
const [nodeUrl, setNodeUrl] = useState(nodeUrls[0])
  const [contractAddress, setContractAddress] = useState(
    "0x078f70f1ce52f09360dd1d04ad27bde431098b6285153aed16a74b3620253b65"
  );
  const [typeofChain, setType] = useState("Stark Mainnet");
  const [priceFaucet, setpriceFauce] = useState("1");

  const [isNotification, setIsNotification] = useState(true);

  useEffect(() => {
    if (initData && initData.user) {
      setUser(initData.user);
    }
    const shortAddress = localStorage.getItem(`account_address ${typeofChain} ${userID}`)
    localStorage.setItem(`shortAddress ${typeofChain} ${userID}`, shortAddress?.slice(0, 4) + '...' + shortAddress?.slice(-5))
  }, [initData]);

  if (user) {
    localStorage.setItem('userID', user.id.toString())
  }

  const userID = localStorage.getItem('userID')

  const getNetworkCredentials = () => {
    if (typeofChain === "Stark Mainnet") {
      return {
        senderAddress:
          // "0x02ef1b64dd2d1c862a8bf1553763eac051027682f986c72b52bd92d99c6fd8a6",
          "0x023a9c852753beb487cb5fa73ca6e76fcd0854aec793ad634f692168e61dafa0",
        privatekey:
          // "0x2ec9a1d960dbfa06d13fb13885a6ffd7f2d83cf50e97bd3a3c03b9a71a66ee",
          "0x025566fb200ec14a3e64483e21dacf3dbfe6bb0166bb190ff67408de1a366476",
        uri: 'https://api.cartridge.gg/x/dislands-core-mainnet/torii/graphql',
        accountAddress: localStorage.getItem(`account_address Mainnet ${user?.id}`),
        deployAddress: localStorage.getItem(`Deploy_address Mainnet ${user?.id}`),
        privateKey: localStorage.getItem(`private_key Mainnet ${user?.id}`),
        publicKey: localStorage.getItem(`public_key Mainnet ${user?.id}`),
        checkFaucetStatus: localStorage.getItem(`faucetCompleted Mainnet ${user?.id}`)
      };
    } else if (typeofChain === "Stark Testnet") {
      return {
        senderAddress:
          "0x16bd5d7a71842258f61ae93c647833495eeb4a2d1b93925b30afd35495bfbb1",
        privatekey:
          "0x581be315f91ff849daa0ed4ceeba8d7e1ce908bcb0f15dac7654076e448b50b",
        uri: 'https://api.cartridge.gg/x/dislands-core-sepolia/torii/graphql',
        deployAddress: localStorage.getItem(`Deploy_address Testnet ${user?.id}`),
        accountAddress: localStorage.getItem(`account_address Testnet ${user?.id}`),
        privateKey: localStorage.getItem(`private_key Testnet ${user?.id}`),
        publicKey: localStorage.getItem(`public_key Testnet ${user?.id}`),
        checkFaucetStatus: localStorage.getItem(`faucetCompleted Testnet ${user?.id}`)
      };
    }
    return {
      senderAddress: "",
      privatekey: "",
      deployAddress: "",
      accountAddress: "",
      privateKey: "",
      publicKey: "",
      uri: "",
    };
  };
  const { senderAddress, privatekey, deployAddress, accountAddress, privateKey, publicKey, uri } = getNetworkCredentials();

  //Speed
  const miningSpeed = {
    price: SPEED_COST_LEVEL_1_TO_2,
    currentLevel: speedLevel,
    nextLevel: speedLevel + 1,
    rate_current: SPEED_RATE_LEVEL_1,
    rate_next: SPEED_RATE_LEVEL_2,
  };

  switch (speedLevel) {
    case 1:
      miningSpeed.price = SPEED_COST_LEVEL_1_TO_2;
      miningSpeed.rate_current = SPEED_RATE_LEVEL_1;
      miningSpeed.rate_next = SPEED_RATE_LEVEL_2;
      break;
    case 2:
      miningSpeed.price = SPEED_COST_LEVEL_2_TO_3;
      miningSpeed.rate_current = SPEED_RATE_LEVEL_2;
      miningSpeed.rate_next = SPEED_RATE_LEVEL_3;
      break;
    case 3:
      miningSpeed.price = SPEED_COST_LEVEL_3_TO_4;
      miningSpeed.rate_current = SPEED_RATE_LEVEL_3;
      miningSpeed.rate_next = SPEED_RATE_LEVEL_4;
      break;
    case 4:
      miningSpeed.price = SPEED_COST_LEVEL_4_TO_5;
      miningSpeed.rate_current = SPEED_RATE_LEVEL_4;
      miningSpeed.rate_next = SPEED_RATE_LEVEL_5;
      break;
    case 5:
      miningSpeed.price = SPEED_COST_LEVEL_5_TO_6;
      miningSpeed.rate_current = SPEED_RATE_LEVEL_5;
      miningSpeed.rate_next = SPEED_RATE_LEVEL_6;
      break;
    case 6:
      miningSpeed.price = 999999999999999;
      miningSpeed.rate_current = SPEED_RATE_LEVEL_6;
      miningSpeed.rate_next = SPEED_RATE_LEVEL_6;
      break;
    default:
      console.error("Invalid speed level");
      break;
  }

  //Storage......................

  const miningStorage = {
    price: STORAGE_COST_LEVEL_1_TO_2,
    currentLevel: storageLevel,
    nextLevel: storageLevel + 1,
    claimTime_current: STORAGE_LIMIT_LEVEL_1,
    claimTime_next: STORAGE_LIMIT_LEVEL_2,
  };
  switch (miningStorage.currentLevel) {
    case 1:
      miningStorage.price = STORAGE_COST_LEVEL_1_TO_2;
      miningStorage.claimTime_current = STORAGE_LIMIT_LEVEL_1;
      miningStorage.claimTime_next = STORAGE_LIMIT_LEVEL_2;
      break;
    case 2:
      miningStorage.price = STORAGE_COST_LEVEL_2_TO_2;
      miningStorage.claimTime_current = STORAGE_LIMIT_LEVEL_2;
      miningStorage.claimTime_next = STORAGE_LIMIT_LEVEL_3;
      break;
    case 3:
      miningStorage.price = STORAGE_COST_LEVEL_3_TO_2;
      miningStorage.claimTime_current = STORAGE_LIMIT_LEVEL_3;
      miningStorage.claimTime_next = STORAGE_LIMIT_LEVEL_4;
      break;
    case 4:
      miningStorage.price = STORAGE_COST_LEVEL_4_TO_2;
      miningStorage.claimTime_current = STORAGE_LIMIT_LEVEL_4;
      miningStorage.claimTime_next = STORAGE_LIMIT_LEVEL_5;
      break;
    case 5:
      miningStorage.price = STORAGE_COST_LEVEL_5_TO_2;
      miningStorage.claimTime_current = STORAGE_LIMIT_LEVEL_5;
      miningStorage.claimTime_next = STORAGE_LIMIT_LEVEL_6;
      break;
    case 6:
      miningStorage.price = 999999999999999;
      miningStorage.claimTime_current = STORAGE_LIMIT_LEVEL_6;
      miningStorage.claimTime_next = STORAGE_LIMIT_LEVEL_6;
      break;
    default:
      console.error("Invalid storage level");
      break;
  }

  // Fetch player data using GraphQL
  const addressPlayer = localStorage.getItem(`account_address ${typeofChain} ${userID}`)
  // const addressPlayer = '0x0653e74a1a02f2028816a5f0bd6a4f1bc179c11db9b4fa45ca0b6824398dfef7'

  const { loading, error, data, refetch } = useQuery(GET_PLAYER_DATA, {
    variables: { playerAddress: addressPlayer },
  });
  // Process and set player data
  useEffect(() => {
    if (!loading && !error && data && localStorage.getItem(`faucetCompleted ${typeofChain} ${userID}`) === 'true') {
      setPlayerData(data);
      console.log(data);
  
      const playerModel = data?.dislandsCoreMainnetPlayerModels?.edges?.[0]?.node?.balance ?? 0;
      const badgeModel = data?.dislandsCoreMainnetPlayerModels?.edges?.[0]?.node?.badge ?? 0;
      const storagerModel = data?.dislandsCoreMainnetStorageDataModels?.edges?.[0]?.node?.last_claim ?? 0;
      const statEdges = data?.dislandsCoreMainnetStatModels?.edges ?? [];
  
      // Initial default values
      let statModelStorage = 1;
      let statModelSpeed = 1;
      let statModelBoost = 1;
  
      // Iterate over statEdges and match the upgrade_type
      statEdges.forEach((edge: { node: { upgrade_type: any; level: string; }; }) => {
        const upgradeType = edge.node.upgrade_type;
        const level = parseInt(edge.node.level, 16); // Convert hex to decimal
  
        if (upgradeType === 'Storage') {
          statModelStorage = level;
        } else if (upgradeType === 'Speed') {
          statModelSpeed = level;
        } else if (upgradeType === 'Super_Boost') {
          statModelBoost = level;
        }
      });
  
      const musterModel_day_count = data?.dislandsCoreMainnetMusterModels?.edges?.[0]?.node?.day_count ?? 1;
      const musterModel_last_claim = data?.dislandsCoreMainnetMusterModels?.edges?.[0]?.node?.last_muster ?? 0;
      const referralsEdges = data?.dislandsCoreMainnetReferralsModels?.edges ?? [];
      const referred = referralsEdges.map((edge: { node: { referred: any; }; }) => edge.node.referred);
  
      // Convert hex to decimal and divide by 10 ** 18
      const referredReward = referralsEdges.map((edge: { node: { referal_reward: { toString: () => Decimal.Value; }; }; }) => {
        const rewardInDecimal = new Decimal(edge.node.referal_reward.toString()).dividedBy(10 ** 18).toFixed(7);
        return rewardInDecimal;
      });
  
      // Calculate the total referred reward
      const totalReward = referredReward.reduce((acc: { plus: (arg0: any) => any; }, curr: any) => acc.plus(curr), new Decimal(0)).toFixed(7);
  
      // Safely format the balance and other data
      if (playerModel !== undefined && storagerModel !== undefined) {
        const formattedBalance = new Decimal(playerModel).toFixed(10);
        const balance = roundTo6Decimals(Number(formattedBalance) / 1e18);
        const formattedlast_claim = new Decimal(storagerModel.toString()).toNumber();
        const formattedDayCount = parseInt(musterModel_day_count, 16);
        const formattedLastMuster = parseInt(musterModel_last_claim, 16);
  
        console.log(formattedDayCount, formattedLastMuster);
  
        setBalance(balance);
        setLastClaim(formattedlast_claim);
        setBoost(statModelBoost);
        setStorage(statModelStorage);
        setSpeed(statModelSpeed);
        setBadge(badgeModel);
        setDayCount(formattedDayCount);
        setLastMuster(formattedLastMuster);
  
        // Save data to localStorage
        localStorage.setItem(`lastClaimTime ${typeofChain} ${userID}`, formattedLastMuster.toString());
        localStorage.setItem(`lastClaim ${typeofChain} ${userID}`, formattedlast_claim.toString());
        localStorage.setItem(`dayCount ${typeofChain} ${userID}`, formattedDayCount.toString());
  
        // Logging for debugging
        console.log('levelBoost:', statModelBoost);
        console.log('levelSpeed:', statModelSpeed);
        console.log('levelStorage:', statModelStorage);
        console.log('balance:', balance);
        console.log('lastClaim:', formattedlast_claim);
        console.log('dayCount:', dayCount);
        console.log('lastMuster:', lastMuster);
        console.log('Referred list:', referred);
        console.log('badge:', badgeModel);
        console.log('Referred rewards:', referredReward);
        console.log('Total referred reward:', totalReward);
      } else {
        // Handle the case where playerModel or storagerModel are undefined
        setBalance(0);
        setLastClaim(0);
        setBoost(1);
        setStorage(1);
        setSpeed(1);
        setBadge(0);
        setDayCount(1);
        setLastMuster(0);
      }
  
      setReferredList(referred);
      setReferredReward(referredReward);
      setTotalReferredReward(Number(totalReward));
  
    } else {
      // Set default values in case of an error or no data
      setBalance(0);
      setLastClaim(0);
      setBoost(1);
      setStorage(1);
      setSpeed(1);
      setBadge(0);
      setDayCount(0);
      setLastMuster(0);
      setReferredList([]);
      setReferredReward([]);
      setTotalReferredReward(0);
    }

    refetch();
  }, [loading, data, error, triggerEffect]);
  const handleCopyAddress = (value: string | null) => {
    if (value) {
      // Try using the Clipboard API
      navigator.clipboard
        .writeText(value)
        .then(() => {
          showToast("Copied to clipboard!", "", "success");
        })
        .catch((err) => {
          console.error("Failed to copy:", err);

          // Fallback for older browsers
          if (typeof value === "string") {
            const textarea: HTMLTextAreaElement = document.createElement("textarea");
            textarea.value = value;
            // Make textarea invisible (important for iOS/Android)
            textarea.style.position = "fixed";
            textarea.style.left = "-9999px";
            document.body.appendChild(textarea);
            textarea.focus();
            textarea.select();
            try {
              const successful = document.execCommand("copy");
              if (successful) {
                showToast("Copied to clipboard!", "", "success");
              } else {
                throw new Error("Fallback copy failed");
              }
            } catch (fallbackErr) {
              console.error("Fallback copy failed:", fallbackErr);
              showToast("Failed to copy", "", "error");
            }
            document.body.removeChild(textarea);
          }
        });
    }
  };


  const handleChange = (network: "mainnet" | "testnet") => {
    let newUrl;
    let newContractAddress;
    let newType;
    let newPrice;

    if (network === "testnet") {
      newUrl =
        "https://rpc.nethermind.io/sepolia-juno?apikey=hUNGPMNip8opaYfLufLVFTOoqrp8eApqqNM2CL24Pf1yEA4y";
      newContractAddress =
        "0x05244791f362b95a8577bc1850a4849f29e6a8f71047c1dbdda77e0d02a91f9c";
      newType = "Stark Testnet";
      newPrice = "3";
    } else if (network === "mainnet") {
      newUrl =
        "https://free-rpc.nethermind.io/mainnet-juno";
      newContractAddress =
        "0x078f70f1ce52f09360dd1d04ad27bde431098b6285153aed16a74b3620253b65";
      newType = "Stark Mainnet";
      newPrice = "0.1";
    }

   
    setContractAddress(
      newContractAddress ||
      "0x078f70f1ce52f09360dd1d04ad27bde431098b6285153aed16a74b3620253b65"
    );
    localStorage.setItem(
      "nodeUrl",
      newUrl ||
      "https://free-rpc.nethermind.io/mainnet-juno"
    );
    localStorage.setItem(
      "contractAddress",
      newContractAddress ||
      "0x078f70f1ce52f09360dd1d04ad27bde431098b6285153aed16a74b3620253b65"
    );
    setType(newType || "Stark Mainnet")
    setpriceFauce(newPrice || "3")
    localStorage.setItem("typeof", newType || "Stark Mainnet");
    localStorage.setItem("priceFaucet", newPrice || "3");
  };

  const fetchCoinAmount = async () => {
    const accountKey = `account_address ${typeofChain} ${userID}`;
    const deployAddress = localStorage.getItem(accountKey);
  
    if (deployAddress) {
      let index = 0;
      let provider;
      let success = false;
  
      while (index < nodeUrls.length && !success) {
        try {
          provider = new RpcProvider({
            nodeUrl: nodeUrls[index],
          });
          
          const strkToken = "0x04718f5a0fc34cc1af16a1cdee98ffb20c31f5cd61d6ab07201858f4287c938d";
          const { abi: Abi } = await provider.getClassAt(strkToken);
  
          if (!Abi) throw new Error("No ABI found.");
  
          const myContract = new Contract(Abi, strkToken, provider);
          const _balance = await myContract.balance_of(deployAddress);
          
          const decimals = new Decimal(10).pow(18);
          const balance = new Decimal(_balance.toString()).div(decimals);
          const coinAmount = balance.toNumber();
  
          setCoinAmount(coinAmount);
          console.log("Coin amount:", coinAmount);
  
          if (coinAmount !== 0 && coinAmount !== 0.3) {
            localStorage.setItem(`${deployAddress} coin`, 'done');
          }
  
          const response = await axios.get(
            `https://starknet.api.avnu.fi/swap/v2/prices?sellTokenAddress=0x4718f5a0fc34cc1af16a1cdee98ffb20c31f5cd61d6ab07201858f4287c938d&buyTokenAddress=0x53c91253bc9682c04929ca02ed00b3e423f6710d2ee7e0d5ebb06f3ecf368a8&sellAmount=0x${_balance.toString(16)}`
          );
  
          const data = response.data;
          setTokenAmount(data[0].sellAmountInUsd);
  
          success = true;  // If everything works, mark success as true to exit the loop
        } catch (error) {
          console.error(`Error fetching coin amount with nodeUrl ${nodeUrls[index]}:`, error);
          index += 1; // Try the next URL
        }
      }
  
      if (!success) {
        console.error("All node URLs failed.");
      }
    }
  };
  

  useEffect(() => {
    fetchCoinAmount();
    if (coinAmount !== 0 && coinAmount !== 0.5) { localStorage.setItem(`${deployAddress} coin`, 'done') }
  }, []);

  // Example usage of handleChange
  const switchToMainnet = () => handleChange("mainnet");
  const switchToTestnet = () => handleChange("testnet");

  // Handle account name change
  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAccountName(e.target.value);
  };

  // Submit and save account name
  const handleNameSubmit = () => {
    localStorage.setItem("account_name", accountName);
    setIsEditing(false);
  };

  // Determine sender address and private key based on the current network
  // Example of using the network credentials
  const provider = new RpcProvider({
    nodeUrl: nodeUrls[0],
  });
  const accountAX = new Account(provider, senderAddress, privatekey);

  let fee;
  // console.log (coinAmount);
  switch (true) {
    case coinAmount < 0.09:
      fee = 10n;
      break;
    case coinAmount >= 0.09:
      fee = 10n;
      break;
    default:
      fee = 10n;
  }

  const [shortAddress, setShortAddres] = useState('0x0...404');

  useEffect(() => {
    const savedShortAddress = localStorage.getItem(`shortAddress ${typeofChain} ${userID}`);

    if (savedShortAddress) {
      setShortAddres(savedShortAddress);
    }
    console.log('Hello World')
  }, [typeofChain, userID, accountAddress]);


  // console.log ("fee: ", fee);
  // console.log ("total: ", coinAmountFaucet);

  const [transactionHashes, setTransactionHashes] = useState<TransactionHash[]>(() => {
    const storedHashes = localStorage.getItem(`List txH ${typeofChain} ${userID}`);
    return storedHashes ? JSON.parse(storedHashes) : [];
  });

  const addTransactionHash = (hash: string, type: string) => {
    const date = new Date();
    const timestamp = `${date.toLocaleTimeString("vi-VN", { hour: '2-digit', minute: '2-digit' })} ${date.toLocaleDateString("vi-VN")}`;
  
    setTransactionHashes((prevHashes) => {
      const updatedHashes = [...prevHashes, { hash, type, timestamp }];
      localStorage.setItem(`List txH ${typeofChain} ${userID}`, JSON.stringify(updatedHashes));
      return updatedHashes;
    });
  };
  

  // Optional: Cập nhật lại transactionHashes khi component được mount
  useEffect(() => {
    const storedHashes = localStorage.getItem(`List txH ${typeofChain} ${userID}`);
    if (storedHashes) {
      setTransactionHashes(JSON.parse(storedHashes));
    }
  }, []);

  return (
    <AccountContext.Provider
      value={{
        userID,
        uri,
        setType,
        user,
        referredList,
        referredReward,
        totalReferredReward,
        shortAddress,
        accountAddress,
        nodeUrl,
        setNodeUrl,
        fee,
        setBalance,
        publicKey,
        balance_new,
        contractAddress,
        coinAmount,
        tokenAmount,
        accountName,
        isEditing,
        deployAddress,
        privateKey,
        badge,
        accountAX,
        strk_token:
          "0x04718f5a0fc34cc1af16a1cdee98ffb20c31f5cd61d6ab07201858f4287c938d",
        playerData,
        balanceModels,
        last_claim,
        boostLevel,
        storageLevel,
        speedLevel,
        miningSpeed,
        miningStorage,
        dayCount,
        lastMuster,
        typeofChain,
        priceFaucet,
        switchToMainnet,
        switchToTestnet,
        handleCopyAddress,
        fetchCoinAmount,
        handleNameChange,
        handleNameSubmit,
        setIsEditing,
        triggerEffect,
        setTriggerEffect,
        transactionHashes,
        addTransactionHash,
        isNotification,
        setIsNotification
      }}
    >
      {children}
    </AccountContext.Provider>
  );
};
