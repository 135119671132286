// Create a font loading utility
export const loadFonts = () => {
  const fonts = [
    {
      family: 'Open Sans',
      weights: [400, 600, 700],
      display: 'swap'
    },
    {
      family: 'Rowdies',
      weights: [300, 400, 700],
      display: 'swap'
    }
  ];

  fonts.forEach(font => {
    const link = document.createElement('link');
    link.href = `https://fonts.googleapis.com/css2?family=${font.family.replace(' ', '+')}:wght@${font.weights.join(';')}&display=${font.display}`;
    link.rel = 'stylesheet';
    document.head.appendChild(link);
  });
}; 