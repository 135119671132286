// Speed Config
export const SPEED_RATE_LEVEL_1 = (1 / 25); // 0.04 
export const SPEED_RATE_LEVEL_2 = (3 / 50); // 0.06
export const SPEED_RATE_LEVEL_3 = (2 / 25); // 0.08
export const SPEED_RATE_LEVEL_4 = (1 / 10); // 0.1
export const SPEED_RATE_LEVEL_5 = (3 / 25); // 0.12
export const SPEED_RATE_LEVEL_6 = (1 / 5); // 0.2

export const SPEED_COST_LEVEL_1_TO_2 = 1.6;
export const SPEED_COST_LEVEL_2_TO_3 = 8; 
export const SPEED_COST_LEVEL_3_TO_4 = 16; 
export const SPEED_COST_LEVEL_4_TO_5 = 40; 
export const SPEED_COST_LEVEL_5_TO_6 = 120;

// Storage Hour Limit Config
export const STORAGE_LIMIT_LEVEL_1 = 2;
export const STORAGE_LIMIT_LEVEL_2 = 3;
export const STORAGE_LIMIT_LEVEL_3 = 4;
export const STORAGE_LIMIT_LEVEL_4 = 6;
export const STORAGE_LIMIT_LEVEL_5 = 12;
export const STORAGE_LIMIT_LEVEL_6 = 24;

export const STORAGE_COST_LEVEL_1_TO_2 = 1.6;
export const STORAGE_COST_LEVEL_2_TO_2 = 4;
export const STORAGE_COST_LEVEL_3_TO_2 = 8;
export const STORAGE_COST_LEVEL_4_TO_2 = 32;
export const STORAGE_COST_LEVEL_5_TO_2 = 64;

// Super Boost Config
export const SUPER_BOOST_MULTIPLE_LEVEL_1 = 1;
export const SUPER_BOOST_MULTIPLE_LEVEL_2 = (6 / 5); // 1.2
export const SUPER_BOOST_MULTIPLE_LEVEL_3 = (7 / 5); // 1.4
export const SUPER_BOOST_MULTIPLE_LEVEL_4 = (8 / 5); // 1.6
export const SUPER_BOOST_MULTIPLE_LEVEL_5 = (9 / 5); // 1.8
export const SUPER_BOOST_MULTIPLE_LEVEL_6 = 2; 

export const SUPER_BOOST_MISSION_LEVEL_1_TO_2 = 1;
export const SUPER_BOOST_MISSION_LEVEL_2_TO_2 = 2;
export const SUPER_BOOST_MISSION_LEVEL_3_TO_2 = 3;
export const SUPER_BOOST_MISSION_LEVEL_4_TO_2 = 4;
export const SUPER_BOOST_MISSION_LEVEL_5_TO_2 = 5;
