import React from 'react';
import { Center } from "@chakra-ui/react";
import Navbar from '@/components/component/Navbar';
import { TabsView } from '@/components/component/TabsView';

interface LayoutProps {
  children: React.ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
  return (
    <Center
      h={'100dvh'}
      flexDirection={'column'}
      justifyContent={'space-between'}
      backgroundColor={"#90DEE4"}
    >
        <Navbar />
        <Center w={'100vw'}  flexDirection={'column'} h={`calc(100vh - 143px)`}>
        {children}
        </Center>
          <TabsView />
    </Center>
  );
};

export default Layout;