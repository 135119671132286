import React, { useState } from 'react';
import { Box } from '@chakra-ui/react';

interface SwipeableToastProps {
  onSwipe: () => void;
  children: React.ReactNode;
}

const SwipeableToast: React.FC<SwipeableToastProps> = ({ onSwipe, children }) => {
  const [startX, setStartX] = useState<number | null>(null);

  const handleTouchStart = (e: React.TouchEvent) => {
    setStartX(e.touches[0].clientX);
  };

  
  const handleTouchEnd = (e: React.TouchEvent) => {
    if (startX === null) return;
    const endX = e.changedTouches[0].clientX;
    if (Math.abs(endX - startX) > 100) {
      onSwipe();
    }
    setStartX(null);
  };

  const handleMouseDown = (e: React.MouseEvent) => {
    setStartX(e.clientX);
  };

  const handleMouseUp = (e: React.MouseEvent) => {
    if (startX === null) return;
    const endX = e.clientX;
    if (Math.abs(endX - startX) > 100) {
      onSwipe();
    }
    setStartX(null);
  };

  return (
    <Box
      onTouchStart={handleTouchStart}
      onTouchEnd={handleTouchEnd}
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
    >
      {children}
    </Box>
  );
};

export default SwipeableToast;
