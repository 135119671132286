export function getMinutesAgo(timestamp: number): number {
    const now = Date.now();
    const diffInMilliseconds = now - timestamp;
    const diffInMinutes = Math.floor(diffInMilliseconds / (1000 * 60));
    return diffInMinutes;
}

export function roundTo18Decimals(value: number): number {
    return Math.round(value * 1e18) / 1e18;
  }

  export function roundTo2Decimals(value: number): number {
    return Math.round(value * 1000) / 1000;
  }

  export function roundTo16Decimals(value: number): number {
    return Math.round(value * 1e16) / 1e16;
  }

  export function roundTo6Decimals(value: number): number {
    return Math.round(value * 1e6) / 1e6;
  }

  export function roundTo10Decimals(value: number): number {
    return Math.round(value * 1e10) / 1e10;
  }

  export function roundTo8Decimals(value: number): number {
    return Math.round(value * 1e8) / 1e8;
  }