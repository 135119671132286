import React, {
  createContext,
  useContext,
  ReactNode,
  useEffect,
  useState,
} from "react";
import {
  useToast,
  ToastProps,
  ToastId,
  Progress,
  Center,
  Spinner,
} from "@chakra-ui/react";
import { Box, Text, Icon } from "@chakra-ui/react";
import { FaCheckCircle, FaCopy, FaExclamationCircle } from "react-icons/fa";
import "./loading.css";
import SwipeableToast from "./SwipeableToast"; // Import the reusable component

interface ToastContextProps {
  showToast: (
    title: string,
    description: string,
    status: ToastProps["status"],
    copyText?: string
  ) => void;
  showLoadingToast: (description: string, description2: string) => ToastId;
  hideToast: (id: ToastId) => void;
}

const ToastContext = createContext<ToastContextProps | undefined>(undefined);

export const ToastProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const toast = useToast();
  const [displayedToasts, setDisplayedToasts] = useState<Set<ToastId>>(
    new Set()
  );

  const handleCopy = (value: string | null) => {
    if (value) {
      // Attempt to use the Clipboard API
      navigator.clipboard
        .writeText(value)
        .then(() => {
          showToast("Copied to clipboard!", "", "success");
        })
        .catch((err) => {
          console.error("Failed to copy:", err);

          // Fallback to older method
          const textarea = document.createElement("textarea");
          textarea.value = value;
          document.body.appendChild(textarea);
          textarea.select();
          try {
            document.execCommand("copy");
            showToast("Copied to clipboard!", "", "success");
          } catch (fallbackErr) {
            console.error("Fallback copy failed:", fallbackErr);
            showToast("Failed to copy", "", "error");
          }
          document.body.removeChild(textarea);
        });
    }
  };

  const showToast = (
    title: string,
    description: string,
    status: ToastProps["status"],
    copyText?: string
  ) => {
    const toastId = `${title}-${status}`;

    // Kiểm tra nếu toast đã được hiển thị
    if (displayedToasts.has(toastId)) {
      return;
    }

    // Cập nhật danh sách các toast đã được hiển thị
    setDisplayedToasts((prev) => new Set(prev).add(toastId));

    const ToastContent: React.FC = () => {
      const [progress, setProgress] = useState(100);

      useEffect(() => {
        const interval = setInterval(() => {
          setProgress((prev) => {
            if (prev <= 0) {
              clearInterval(interval);
              return 0;
            }
            return prev - 0.1;
          });
        }, 7);

        return () => clearInterval(interval);
      }, []);

      return (
        <SwipeableToast onSwipe={() => toast.close(toastId)}>
          <Box
            borderRadius="md"
            bg="white"
            color="gray.800"
            p="4"
            boxShadow="lg"
            maxWidth="400px"
            maxHeight={"50dvh"}
            overflowY={"scroll"}
            fontFamily={"Open Sans"}
            textAlign="left"
            position="relative"
            display="flex"
            alignItems="center"
            whiteSpace="pre-wrap"
            overflowWrap="break-word"
            wordBreak="break-word"
            border="1px solid"
            borderColor={
              status === "success"
                ? "green.500"
                : status === "error"
                ? "red.500"
                : status === "warning"
                ? "yellow.500"
                : "blue.500"
            }
          >
            {status === "success" && (
              <Icon as={FaCheckCircle} boxSize={6} color="green.500" mr="3" />
            )}
            {status === "error" && (
              <Icon
                as={FaExclamationCircle}
                boxSize={6}
                color="red.500"
                mr="3"
              />
            )}
            <Box flex="1">
              <Text fontWeight="bold" fontSize="lg" color="gray.800">
                {title}
              </Text>
              <Text mt="1" fontSize="md" color="gray.600">
                {description}
              </Text>
            </Box>
            {copyText && (
              <Center
                cursor={"pointer"}
                padding={"3px"}
                onClick={() => handleCopy(copyText)}
                onTouchStart={() => handleCopy(copyText)}
                w="25px"
                h={"25px"}
                position="absolute"
                top="2"
                right="25px"
                gap={"10px"}
                borderColor="gray.300"
                color="gray.600"
                _active={{ transition: "scale(0.1)" }}
                borderRadius={"50%"}
              >
                <Icon as={FaCopy} boxSize={4} />
              </Center>
            )}
            <Box
              as="span"
              cursor={"pointer"}
              fontSize="20px"
              position="absolute"
              top="2"
              right="10px"
              color="gray.600"
              fontWeight={"800"}
              fontFamily={"Open Sans"}
              _active={{ transition: "scale(0.1)" }}
              onClick={() => toast.close(toastId)}
              onTouchStart={() => toast.close(toastId)}
            >
              ×
            </Box>
            <Progress
              position="absolute"
              bottom="0"
              left="0"
              width="100%"
              height="4px"
              colorScheme={
                  status === "success"
                    ? "green.500"
                    : status === "error"
                    ? "red.500"
                    : status === "warning"
                    ? "yellow"
                    : "blue.500"
              }
              value={progress}
              borderBottomRadius="md"
            />
          </Box>
        </SwipeableToast>
      );
    };

    toast({
      position: "top-right",
      duration: 7000,
      isClosable: true,
      variant: "solid",
      render: () => <ToastContent />,
      status: status,
      id: toastId,
      onCloseComplete: () => {
        setDisplayedToasts((prev) => {
          const newSet = new Set(prev);
          newSet.delete(toastId);
          return newSet;
        });
      },
    });
  };

  const showLoadingToast = (
    description: string,
    description2: string
  ): ToastId => {
    const existingLoadingToastId = "loading"; // ID for loading toast

    // Check if there are any active toasts and close the previous one
    if (displayedToasts.has(existingLoadingToastId)) {
      toast.close(existingLoadingToastId);
      setDisplayedToasts((prev) => {
        const newSet = new Set(prev);
        newSet.delete(existingLoadingToastId);
        return newSet;
      });
    }

    // Ensure all other toasts are closed before showing a new one
    toast.closeAll();

    // Display the new loading toast
    const toastId = existingLoadingToastId;

    setDisplayedToasts((prev) => new Set(prev).add(toastId));

    const ToastContent: React.FC = () => {
      return (
        <SwipeableToast onSwipe={() => toast.close(toastId)}>
          <Box
            borderRadius="lg"
            borderColor="1px solid #EE6C49"
            bg={"white"}
            color="#EE6C49"
            p="4"
            maxWidth="400px"
            textAlign="left"
            display="flex"
            alignItems="center"
            whiteSpace="pre-wrap"
            overflowWrap="break-word"
            wordBreak="break-word"
            boxShadow="md"
            position={"relative"}
          >
            <Spinner size="sm" mr="2" />

            <Box
              as="span"
              cursor={"pointer"}
              fontSize="lg"
              position="absolute"
              top="2"
              right="4"
              color="gray.600"
              fontWeight={"800"}
              fontFamily={"Open Sans"}
              _active={{ transition: "scale(0.1)" }}
              onClick={() => toast.close(toastId)}
              onTouchStart={() => toast.close(toastId)}
            >
              ×
            </Box>

            <Text
              fontSize="md"
              fontWeight={800}
              fontFamily={"Open Sans"}
              color="#EE6C49"
            >
              {description}
              <br></br>
              {description2}
            </Text>

            <Box
              position="absolute"
              bottom="0"
              left="0"
              width="100%"
              height="4px"
              borderBottomRadius="md"
              bg={"orange.500"}
              overflow="hidden"
            >
              <Box
                width="100%"
                height="100%"
                bgGradient="linear(to-r, orange.500, white)"
                transform="scaleX(-1)"
                transformOrigin="center"
                position="relative"
              />
            </Box>
          </Box>
        </SwipeableToast>
      );
    };

    return toast({
      position: "top-right",
      duration: null, // Toast will not close automatically
      isClosable: false,
      variant: "solid",
      render: () => <ToastContent />,
      status: "info",
      id: toastId,
      onCloseComplete: () => {
        setDisplayedToasts((prev) => {
          const newSet = new Set(prev);
          newSet.delete(toastId);
          return newSet;
        });
      },
    });
  };

  const hideToast = (id: ToastId) => {
    toast.close(id);
    setDisplayedToasts((prev) => {
      const newSet = new Set(prev);
      newSet.delete(id);
      return newSet;
    });
  };

  return (
    <ToastContext.Provider value={{ showToast, showLoadingToast, hideToast }}>
      {children}
    </ToastContext.Provider>
  );
};

export const useToastContext = (): ToastContextProps => {
  const context = useContext(ToastContext);
  if (!context) {
    throw new Error("useToastContext must be used within a ToastProvider");
  }
  return context;
};
