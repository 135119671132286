// src/App.tsx
import { useIntegration } from '@telegram-apps/react-router-integration';
import {
  bindMiniAppCSSVars,
  bindThemeParamsCSSVars,
  initNavigator,
  initMiniApp,
  initThemeParams,
  initViewport,
} from '@telegram-apps/sdk';

import { retrieveLaunchParams } from '@telegram-apps/sdk';
import { AppRoot } from '@telegram-apps/telegram-ui';
import { FC, useEffect, useMemo, useState } from 'react';
import { Navigate, Route, Router, Routes } from 'react-router-dom';
import React from 'react';
import "@fontsource/rowdies"; 
import "@fontsource/open-sans";
import { routes } from '@/navigation/routes'; // Ensure this file exists and is correctly imported
import Layout from '@/Layout/Layout';
import Layout2 from '@/Layout/Layout2';
import '/img/Island.svg';
import { ErrorBoundary } from './ErrorBoundary';

export const App: FC = () => {
  const lp = retrieveLaunchParams();
  const [miniApp] = initMiniApp(); // No destructuring required
  const [themeParams] = initThemeParams(); // No destructuring required
  const [, setViewport] = useState<any>(null); // Initial state for viewport

  // Fetch the viewport asynchronously
  useEffect(() => {
    const fetchViewport = async () => {
      const vp = await initViewport();
      setViewport(vp);
    };
    fetchViewport();
  }, []);

  // Bind MiniApp and ThemeParams CSS vars
  useEffect(() => {
    return bindMiniAppCSSVars(miniApp, themeParams);
  }, [miniApp, themeParams]);

  // Bind ThemeParams CSS vars
  useEffect(() => {
    return bindThemeParamsCSSVars(themeParams);
  }, [themeParams]);

  // Bind Viewport CSS vars only after viewport is set
 

  const navigator = useMemo(() => initNavigator('app-navigation-state'), []);
  const [location, reactNavigator] = useIntegration(navigator);

  useEffect(() => {
    navigator.attach();
    return () => navigator.detach();
  }, [navigator]);

  return (
    <AppRoot
      appearance={miniApp.isDark ? 'dark' : 'light'}
      platform={['macos', 'ios'].includes(lp.platform) ? 'ios' : 'base'}
    >
      <ErrorBoundary>
        <Router location={location} navigator={reactNavigator}>
          <Routes>
            {routes.map((route) => {
              const LayoutComponent = route.layout === '1' ? Layout 
                                    : route.layout === '2' ? Layout2
                                    : React.Fragment;

              return (
                <Route
                  key={route.path}
                  path={route.path}
                  element={
                    <LayoutComponent>
                      <route.component />
                    </LayoutComponent>
                  }
                />
              );
            })}
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        </Router>
      </ErrorBoundary>
    </AppRoot>
  );
};
