import { Flex, Box} from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";
import tab1 from '/img/Mission.svg';
// import SwipeableToast from '@/Context/SwipeableToast';
import { Img } from '@chakra-ui/react';

export function TabsView() {
    // const toast = useToast();

    // const handleClick = (message: string) => {
    //     const toastId = "coming_soon..."; // Unique ID for the toast
    //     if (!toast.isActive(toastId)) { // Check if a toast with this ID is already active
    //         toast({
    //             id: toastId, // Assign the ID to the toast
    //             duration: 5000,
    //             position: "top-right",
    //             isClosable: true,
    //             render: () => (
    //                 <SwipeableToast onSwipe={() => toast.closeAll()}>
    //                     <Box
    //                         borderRadius="md"
    //                         bg="blue.500"
    //                         color="gray.800"
    //                         p="4"
    //                         boxShadow="lg"
    //                         maxWidth="400px"
    //                         textAlign="left"
    //                         position="relative"
    //                         display="flex"
    //                         alignItems="center"
    //                         whiteSpace="pre-wrap"
    //                         overflowWrap="break-word"
    //                         wordBreak="break-word"
    //                         border="1px solid"
    //                         borderColor="white"
    //                     >
    //                         <Box flex="1">
    //                             <Box fontWeight="bold" fontSize="lg" color="white">
    //                                 {message}
    //                             </Box>
    //                         </Box>
    //                         <Box
    //                             as="span"
    //                             fontSize="lg"
    //                             position="absolute"
    //                             top="2"
    //                             right="2"
    //                             color="white"
    //                             _active={{ transition: 'scale(0.1)' }}
    //                             onClick={() => toast.closeAll()}
    //                         >
    //                             ×
    //                         </Box>
    //                         {/* Progress bar */}
    //                         <Box position="absolute" bottom="0" left="0" w="100%" h="4px" bg="gray.300">
    //                             <Box h="100%" bg="blue.700" animation={`${progress} 5s linear`} />
    //                         </Box>
    //                     </Box>
    //                 </SwipeableToast>
    //             ),
    //         });
    //     }
    // };

    // const progress = keyframes`
    //     from { width: 100%; }
    //     to { width: 0%; }
    // `;

    return (
        <Flex
            w={'95vw'}
            h={'88px'}
            border={'6px solid #027F8B80'}
            borderRadius={'20px'}
            bg={'#FFF6E4'}
            justifyContent={'space-between'}
            align={'center'}
            mb={'5px'}
            zIndex={ 3}
        >
            <Flex
                w={'25%'}
                h={'100%'}
                flexDirection={'column'}
                justifyContent={'center'}
                alignItems={'center'}
                as={RouterLink}
                to={'/missions'}
            >
                <Img src={tab1} h={'36px'} />
                <Box
                    textAlign={'center'}
                    fontFamily={'Open Sans'}
                    fontSize={'11px'}
                    fontWeight={600}
                    color={'black'}

                >
                    Missions
                </Box>
            </Flex>
            <Img src='/img/line.svg' position={'relative'} />
            <Flex
                w={'25%'}
                h={'100%'}
                flexDirection={'column'}
                justifyContent={'center'}
                alignItems={'center'}
                as={RouterLink}
                to={'/upgrade'}
            >
                <img src='/img/Upgrade.svg' height={'36px'} />
                <Box
                    textAlign={'center'}
                    fontFamily={'Open Sans'}
                    fontSize={'11px'}
                    fontWeight={600}
                    color={'black'}
                >
                    Upgrade
                </Box>
            </Flex>
            <Img src='/img/line.svg' position={'relative'} />
            <Flex
                w={'25%'}
                h={'100%'}
                flexDirection={'column'}
                justifyContent={'center'}
                alignItems={'center'}
                as={RouterLink}
                to={'/friends'}
                // onClick={() => handleClick("COMING SOON...")}
            >
                <Img src='/img/Friend.svg' h={'36px'} />
                <Box
                    textAlign={'center'}
                    fontFamily={'Open Sans'}
                    fontSize={'12px'}
                    fontWeight={600}
                    color={'black'}
                >
                    Friends
                </Box>
            </Flex>
            <Img src='/img/line.svg' position={'relative'} />
            <Flex
                w={'25%'}
                h={'100%'}
                flexDirection={'column'}
                justifyContent={'center'}
                alignItems={'center'}
                as={RouterLink}
                to={'/airdrop'}
                // onClick={() => handleClick("COMING SOON...")}
            >
                <Img src='/img/Airdrop.svg' h={'36px'} />
                <Box
                    textAlign={'center'}
                    fontFamily={'Open Sans'}
                    fontSize={'12px'}
                    fontWeight={600}
                    color={'black'}
                >
                    Airdrop
                </Box>
            </Flex>
        </Flex>
    );
}
