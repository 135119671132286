import { Center, Spinner, Box } from '@chakra-ui/react';
import { FC } from 'react';

export const LoadingSpinner: FC = () => (
  <Box position="fixed" top={0} left={0} right={0} bottom={0} zIndex={9999}>
    <Center h="100%">
      <Spinner
        thickness="4px"
        speed="0.65s"
        emptyColor="gray.200"
        color="blue.500"
        size="xl"
      />
    </Center>
  </Box>
); 