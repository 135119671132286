import React from 'react';
import { Center } from "@chakra-ui/react";
import { useMediaQuery } from "react-responsive";
import Navbar from '@/components/component/Navbar';
import { TabsView } from '@/components/component/TabsView';

interface LayoutProps2 {
  children: React.ReactNode;
}

const Layout2: React.FC<LayoutProps2> = ({ children }) => {
      const isDesktopOrLaptop = useMediaQuery({
        query: "(min-height: 500px)",
      });
  return (
    <Center
      h={'100dvh'}
      flexDirection={'column'}
      justifyContent={'space-between'}
      backgroundColor={"#90DEE4"}
    >

        <Navbar />
        <Center w={'100vw'} flexDirection={'column'} h={`calc(100vh - 143px)`}>
        {children}
        </Center>
        {isDesktopOrLaptop && (
            <TabsView />
        )}
    </Center>
  );
};

export default Layout2;