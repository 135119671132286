import { lazy, Suspense } from 'react';
import { ErrorBoundary } from '@/components/ErrorBoundary';
import { LoadingSpinner } from '@/components/LoadingSpinner';

// Wrap each lazy component with Suspense and ErrorBoundary
const withSuspense = (Component: React.ComponentType) => {
  return function WithSuspense(props: any) {
    return (
      <ErrorBoundary>
        <Suspense fallback={<LoadingSpinner />}>
          <Component {...props} />
        </Suspense>
      </ErrorBoundary>
    );
  };
};

// Lazy load components
const StartPage = withSuspense(
  lazy(() => import('@/pages/startpage/startpage').then(m => ({ default: m.StartPage })))
);

const MainBoard = withSuspense(
  lazy(() => import('@/pages/mainboard/FirstIndex').then(m => ({ default: m.MainBoard })))
);

const MainPage = withSuspense(
  lazy(() => import('@/pages/mainpage/MainPage').then(m => ({ default: m.MainPage })))
);

const Wallet = withSuspense(
  lazy(() => import('@/pages/wallet/wallet').then(m => ({ default: m.Wallet })))
);

const Upgrade = withSuspense(
  lazy(() => import('@/pages/upgrade/Upgrade').then(m => ({ default: m.Upgrade })))
);

const Friends = withSuspense(
  lazy(() => import('@/pages/friends/Friends').then(m => ({ default: m.Friends })))
);

const Transfer = withSuspense(
  lazy(() => import('@/pages/wallet/Transfer/Transfer').then(m => ({ default: m.Transfer })))
);

const WalletSetting = withSuspense(
  lazy(() => import('@/pages/wallet/WalletSetting/WalletSetting').then(m => ({ default: m.WalletSetting })))
);

const ShowSecretRecoveryPhrase = withSuspense(
  lazy(() => import('@/pages/wallet/HamburgerButton/AccordingPanel/secret_recovery').then(m => ({ default: m.ShowSecretRecoveryPhrase })))
);

const ExportPrivateKey = withSuspense(
  lazy(() => import('@/pages/wallet/HamburgerButton/AccordingPanel/export_privatekey').then(m => ({ default: m.ExportPrivateKey })))
);

const Airdrop = withSuspense(
  lazy(() => import('@/pages/airdrop/airdrop').then(m => ({ default: m.Airdrop })))
);

const CardKnight = withSuspense(
  lazy(() => import('@/pages/CardKnight/StartPage').then(m => ({ default: m.CardKnight })))
);

const SelectHero = withSuspense(
  lazy(() => import('@/pages/CardKnight/SelectHero').then(m => ({ default: m.SelectHero })))
);

const PlayGame = withSuspense(
  lazy(() => import('@/pages/CardKnight/PlayGame').then(m => ({ default: m.PlayGame })))
);

const PlayAgain = withSuspense(
  lazy(() => import('@/pages/CardKnight/PlayAgain').then(m => ({ default: m.PlayAgain })))
);

const LeaderBoard = withSuspense(
  lazy(() => import('@/pages/leaderboard').then(m => ({ default: m.LeaderBoard })))
);

const Missions = withSuspense(
  lazy(() => import('@/pages/missions/missions').then(m => ({ default: m.Missions })))
);

export const routes = [
  {
    path: '/',
    component: StartPage,
    layout: '0',
    preload: () => {
      import('@/pages/mainboard/FirstIndex');
      import('@/pages/mainpage/MainPage');
    }
  },
  {
    path: '/mainboard',
    component: MainBoard,
    layout: '0',
  },
  {
    path: '/mainpage',
    component: MainPage,
    layout: '1',
  },
  {
    path: '/missions',
    component: Missions,
    layout: '1',
  },
  {
    path: '/wallet',
    component: Wallet,
    layout: '0',
  },
  {
    path: '/upgrade',
    component: Upgrade,
    layout: '2',
  },
  {
    path: '/friends',
    component: Friends,
    layout: '1',
  },
  {
    path: '/airdrop',
    component: Airdrop,
    layout: '1',
  },
  {
    path: '/leaderboard',
    component: LeaderBoard,
    layout: '0',
  },
  {path: '/wallet/transfer', component: Transfer},
  {path: '/wallet/setting', component: WalletSetting},
  {path: '/wallet/privacy_security', component: ShowSecretRecoveryPhrase},
  {path: '/wallet/export_privateKey', component: ExportPrivateKey},
  {path: '/CardKnight', component: CardKnight},
  {path : '/CardKnight/SelectHero', component: SelectHero},
  {path : '/CardKnight/PlayGame', component: PlayGame},
  {path : '/CardKnight/PlayAgain', component: PlayAgain},
];