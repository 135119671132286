import { SDKProvider } from '@telegram-apps/sdk-react';
import { FC, useEffect } from "react";
import { App } from "@/components/App";
import { ErrorBoundary } from "@/components/ErrorBoundary";
import { ChakraProvider, theme } from "@chakra-ui/react";
import { ToastProvider } from "@/Context/ToastContext";
import { AccountProvider } from "@/Context/AccountContext";
import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client';
import { loadFonts } from '@/styles/fonts';
import './Root.css'
// Function to return the specific error message UI
const errorMessenger = () => (
  <div className="container" style={{height: '100vh'}}>
  <h1 className="heading-main">Welcome to D'islands Hub</h1>
  <img src="/img/Disland_Logo.svg" alt="Logo" className="logo" />
  <div>
  <h2 className="heading-sub">Our Hub is exclusive on Telegram</h2>
  <a
    href="https://t.me/dislands_hub_bot/hub"
    className="button-container button-link"
  >
    Open In Telegram
  </a>
  </div>
  
</div>


);

const ErrorBoundaryError: FC<{ error: unknown }> = ({ error }) => {
  const errorMessage =
    error instanceof Error
      ? error.message
      : typeof error === "string"
      ? error
      : JSON.stringify(error);

  // Check for the specific error
  const specificErrorMessage = "Unable to retrieve launch parameters from any known source. Perhaps, you have opened your app outside Telegram?";
  if (errorMessage.includes(specificErrorMessage)) {
    return errorMessenger(); // Call the error messenger function
  }

  // Default error handling
  return (
    <div>
      <p>An unhandled error occurred:</p>
      <blockquote>
        <code>{errorMessage}</code>
      </blockquote>
    </div>
  );
};

const clientMainnet = new ApolloClient({
  uri: 'https://api.cartridge.gg/x/dislands-core-mainnet/torii/graphql',
  cache: new InMemoryCache(),
});

const clientTestnet = new ApolloClient({
  uri: 'https://api.cartridge.gg/x/dislands-core-sepolia/torii/graphql',
  cache: new InMemoryCache(),
});

const Inner: FC = () => {
  // Load fonts when Inner component mounts
  useEffect(() => {
    loadFonts();
  }, []);

  // State to manage the current chain type
  const typeofChain = localStorage.getItem('typeof') || 'Stark Mainnet';

  // Determine which client to use
  const client = typeofChain === 'Stark Mainnet' ? clientMainnet : clientTestnet;

  return (
    <ApolloProvider client={client}>
      <SDKProvider acceptCustomStyles>
        <ChakraProvider theme={theme}>
          <ToastProvider>
            <AccountProvider>
              <App />
            </AccountProvider>
          </ToastProvider>
        </ChakraProvider>
      </SDKProvider>
    </ApolloProvider>
  );
};

export const Root: FC = () => (
  <ErrorBoundary fallback={ErrorBoundaryError}>
    <Inner />
  </ErrorBoundary>
);
